import { useState } from "react";

function Form({ onAddItems }) {
    //States

    const [description, setDescription] = useState("");
    const [quantity, setQuantity] = useState(1);

    //Handlers

    function handleSubmit(event) {
        event.preventDefault();

        if (!description) return;

        const newItem = {
            description,
            quantity,
            packed: false,
            id: Date.now(),
        };
        onAddItems(newItem);
        setDescription("");
        setQuantity(1);
    }

    return (
        <form
            onSubmit={handleSubmit}
            className="bg-[#e5771f] font-quickSand flex justify-center p-8 gap-6 items-center"
        >
            <h3 className="text-2xl font-bold text-[#5a3e2b]">
                What do you need for your trip?
            </h3>
            <select
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
                className="font-bold w-24 rounded-full text-center bg-input h-12"
            >
                {/* "Array.from({ length: 20 }, (_, i) => i + 1)" this part create an Array
                with 20 elements, that we can map after to create the 20 numbers */}
                {Array.from({ length: 20 }, (_, i) => i + 1).map((num) => (
                    <option value={num} key={num}>
                        {num}
                    </option>
                ))}
            </select>
            <input
                type="text"
                placeholder="item..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="bg-input rounded-full w-64 px-2 font-bold h-12"
            />
            <button className="bg-blue font-bold text-xl text-[#5a3e2b] py-2 px-6 rounded-full h-12">
                ADD
            </button>
        </form>
    );
}

export default Form;
