function Stats(props) {
    const { items } = props;

    if (!items.length) {
        return (
            <h4 className="bg-blue h-24 text-center pt-8 font-quickSand font-bold text-2xl text-list">
                Start to add some shit to the list
            </h4>
        );
    }

    const packedPercentage = Math.round(
        (items.filter((item) => item.packed).length * 100) / items.length
    );

    return (
        <footer className="bg-blue h-24 text-center pt-8 font-quickSand font-bold text-2xl text-list">
            {packedPercentage < 100 ? (
                <h4>
                    You have {items.length > 0 ? items.length : 0} items on your
                    list and you already packed{" "}
                    {items.length > 0 ? packedPercentage : 0}% of the total
                </h4>
            ) : (
                <h4>You are ready to go! </h4>
            )}
        </footer>
    );
}

export default Stats;
