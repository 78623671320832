function Item(props) {
    const { item, onDeleteItem, onPackedItemToggle } = props;
    return (
        <div className="font-quickSand text-input flex gap-2">
            <input
                type="checkbox"
                value={item.packed}
                onChange={() => onPackedItemToggle(item.id)}
                className="accent-blue"
            />
            <span style={item.packed ? { textDecoration: "line-through" } : {}}>
                {item.quantity} {item.description}
            </span>
            <button
                onClick={() => onDeleteItem(item.id)}
                className="text-rose-700 font-bold"
            >
                {" "}
                X{" "}
            </button>
        </div>
    );
}

export default Item;
