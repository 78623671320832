import { useState } from "react";
import Item from "./Item";

function PackingList(props) {
    const { items, onDeleteItem, onPackedItemToggle, onClearList } = props;
    const [sortBy, setSortBy] = useState("input");

    let sortedItems;

    if (sortBy === "input") sortedItems = items;
    if (sortBy === "description")
        sortedItems = items
            .slice()
            .sort((a, b) => a.description.localeCompare(b.description));
    if (sortBy === "packed")
        sortedItems = items
            .slice()
            .sort((a, b) => Number(a.packed) - Number(b.packed));

    return (
        <div className="bg-list font-quickSand">
            <div className="grid grid-cols-6 gap-2 mx-4">
                {sortedItems.map((item, index) => (
                    <Item
                        key={index}
                        item={item}
                        onDeleteItem={onDeleteItem}
                        onPackedItemToggle={onPackedItemToggle}
                    />
                ))}
            </div>
            <div className="pt-10 text-center">
                <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="bg-input m-4 py-1 px-8 rounded-full font-bold text-list"
                >
                    <option value="input">Sort by input order</option>
                    <option value="description">Sort by description</option>
                    <option value="packed">Sort by packed status</option>
                </select>
                <button
                    onClick={onClearList}
                    className="bg-input m-4 py-1 px-8 rounded-full font-bold text-list"
                >
                    CLEAR
                </button>
            </div>
        </div>
    );
}

export default PackingList;
