import { useState } from "react";

import Logo from "./components/Logo";
import Form from "./components/Form";
import PackingList from "./components/PackingList";
import Stats from "./components/Stats";

function App() {
    const [items, setItems] = useState([]);

    function handleAddItems(item) {
        setItems((items) => [...items, item]);
    }

    function handleDeleteItem(id) {
        setItems((items) => items.filter((item) => item.id !== id));
    }

    function handlePackedItemToggle(id) {
        setItems((items) =>
            items.map((item) =>
                //This {...item, packed: !item.packed} is how to update an object property in an array
                item.id === id ? { ...item, packed: !item.packed } : item
            )
        );
    }

    function handleClearList() {
        const confirm = window.confirm(
            "Are you sure you want to clear the list?"
        );
        if (confirm) setItems([]);
    }

    return (
        <div>
            <Logo />
            <Form onAddItems={handleAddItems} />
            <PackingList
                items={items}
                onDeleteItem={handleDeleteItem}
                onPackedItemToggle={handlePackedItemToggle}
                onClearList={handleClearList}
            />
            <Stats items={items} />
        </div>
    );
}

export default App;
